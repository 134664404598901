import * as universal from '@/lib/core/universal'
import * as js_functions from '@/lib/js_functions'
import { linkDataVersionsManager, fieldsToUpdate, updateInfo } from '@/lib/core/linkDataVersionsManager'
import { map } from '@/lib/core/components/data/form/map'
import { vtos } from '@/lib/core/public/vtos'
import { iptus } from '@/lib/core/public/iptus'
import { multilines } from '@/lib/core/components/data/form/multilines'
import { dialog } from '@/lib/core/components/dialog'

export function lotes({ page, register }) {
  const _LDVM = new linkDataVersionsManager({ company: 'public', dataVersionName: 'lotes' })
  let lotesCurrentDataId = _LDVM?.getCurrentDataId({ register })
  const lotesLastDataId = _LDVM?.getLastDataId()
  const lotesCurrentLabel = _LDVM?.getVersion({ position: 'dataId', dataId: lotesCurrentDataId }).label
  const lotesLastLabel = _LDVM?.getVersion({ position: 'dataId', dataId: lotesLastDataId }).label
  _LDVM.setLinkDataVersion({ register, versionDataId: lotesCurrentDataId })
  const _iptus = new iptus()
  _iptus.setLinkDataVersion({ register })
  const _vtos = new vtos()
  _vtos.setLinkDataVersion({ register })
  const lotesPolygonsOptions = (type) => { return {
    selected: { fillOpacity: 0.0, strokeColor: "#FF0000", strokeOpacity: 1.0, strokeWeight: 2, zIndex: 10, clickable: true },
    selectedAux: { fillOpacity: 0.0, strokeColor: "#5cb55e", strokeOpacity: 1.0, strokeWeight: 2, zIndex: 10, clickable: true },
    notSelected: { fillColor: "#CCCCCC", fillOpacity: 0.2, strokeColor: "#666666", strokeOpacity: 0.8, strokeWeight: 0.4, zIndex: 1, clickable: true }
  }[type] }
  const polygonsAux = []
  this.polygonsOnMap = {
    lotes: [],
    conflitos: []
  }
  class lotesMap extends map {
    #lotes
    #newLotesMultilines
    #updateInfo
    #lotesCanotUpdate
    #lotesToUpdate
    constructor({ div, lotes, newLotesMultilines }) {
      const key = lotes?.[0]?.info ? 'poligonsComInfo' : 'poligonsSemInfo'
      super({
        field: js_functions.getJSONObject({ fields: page.Form.$refs.FormLines.fields, name: 'poligono' }),
        div,
        [key]: lotes
      })
      this.#lotes = lotes
      this.#newLotesMultilines = newLotesMultilines
      this.#updateInfo = newLotesMultilines.updateInfo
        .map(info => { return {
          ...info,
          status: Array.isArray(info.data?.poligono?.[0]) && info.data?.poligono?.[0].length > 0,
          poligonUpdate: this.#checkPoligonUpdate(info)
        } })
      this.#lotesCanotUpdate = new updateInfo({ data: this.#updateInfo?.filter(info => !info.status) })
      this.#lotesToUpdate = new updateInfo({ data: this.#updateInfo?.filter(info => info.poligonUpdate) })
    }
    #checkPoligonUpdate(info) { return JSON.stringify(info.data?.poligono?.[0]) != JSON.stringify(this.#lotes?.[info.lineIndex].poligono) }
    get isDataIdUpdated() { return this.#newLotesMultilines.isDataIdUpdated }
    get canUpdateDataId() { return !this.#updateInfo.some(info => info.status == false) }
    updateDataId = () => this.#updateInfo?.filter(info => info.poligonUpdate)?.forEach(info => this.#lotes[info.lineIndex].poligono = info.data?.poligono?.[0])
    cannotUpdateText = () => this.#lotesCanotUpdate.notEmpty ? `Os lotes: ${this.#lotesCanotUpdate.getPropList('oldLabel')} não possuem polígonos!` : ''
    // cannotUpdateText = () => this.#lotesCanotUpdate.notEmpty ? `Os lotes: ${this.#lotesCanotUpdate.getRegisterPropList(register, 'link_lote')} não possuem polígonos!` : ''
    sucessUpdatedText = () => this.#lotesToUpdate.notEmpty ? `Os lotes: ${this.#lotesToUpdate.getPropList('oldLabel')} tiveram alterações nos polígonos!` : ''
    // sucessUpdatedText = () => this.#lotesToUpdate.notEmpty ? `Os lotes: ${this.#lotesToUpdate.getRegisterPropList(register, 'link_lote')} tiveram alterações nos polígonos!` : ''
  }
  class lotesMultilines extends multilines {
    #dataId
    #register
    #lastDataId
    #updateInfo
    #lotesCanotUpdate
    #lotesToUpdate
    constructor({ dataId, _register, lastDataId, _updateInfo }) {
      super({
        field: js_functions.getJSONObject({ fields: page.Form.$refs.FormLines.fields, name: 'lotes' }),
        dataId,
        data: _register.lotes
      })
      this.#dataId = dataId
      this.#register = _register
      this.#lastDataId = lastDataId
      this.#updateInfo = _updateInfo
      this.#lotesCanotUpdate = new updateInfo({ data: this.#updateInfo?.filter(info => !info.status) })
      this.#lotesToUpdate = new updateInfo({ data: this.#updateInfo?.filter(info => info.status) })
      this.lote = class line {
        #_id
        constructor({ _id }) {
          this.#_id = _id
        }
        getProp = async (prop) => (await window.jsf.getFunc({ func: () => universal.getGenericRegisters({
          company: 'public',
          dataId: dataId,
          query: { _id: this.#_id },
          project: { [prop]: 1 },
          action: 'read/one'
        }), returnData: true })).data[prop]
      }
    }
    build = async ({ linkDataVersionsManager }) => {
      const _updateInfo = await this.executeInAllLines({ func: async ({ line, index }) => {
        return {
          lineIndex: index,
          oldId: line.lote,
          oldLabel: line.link_lote,
          ...(await linkDataVersionsManager.canUpdateDataId({
            query: { sqlc: await (new this.lote({ _id: line.lote })).getProp('sqlc') },
            project: { sqlc: 1, poligono: 1 }
          }))
          // ,status: false
        }
      }})
      return new lotesMultilines({ dataId: this.#dataId, _register: this.#register, lastDataId: this.#lastDataId, _updateInfo })
    }
    get updateInfo() { return this.#updateInfo }
    get isDataIdUpdated() { return (this.dataId == this.#lastDataId) }
    get canUpdateDataId() { return !this.#updateInfo.some(info => info.status == false) }
    updateDataId = async () => (await this.#updateInfo).forEach(info => this.lines[info.lineIndex].lote = info.data._id)
    cannotUpdateText = () => this.#lotesCanotUpdate.notEmpty ? `Os lotes: ${this.#lotesCanotUpdate.getPropList('oldLabel')} não foram encontrados na nova base de dados!` : ''
    // cannotUpdateText = () => this.#lotesCanotUpdate.notEmpty ? `Os lotes: ${this.#lotesCanotUpdate.getRegisterPropList(this.#register, 'link_lote')} não foram encontrados na nova base de dados!` : ''
    sucessUpdatedText = () => this.#lotesToUpdate.notEmpty ? `Os lotes: ${this.#lotesToUpdate.getPropList('oldLabel')} foram atualizados!` : ''
    // sucessUpdatedText = () => this.#lotesToUpdate.notEmpty ? `Os lotes: ${this.#lotesToUpdate.getRegisterPropList(this.#register, 'link_lote')} foram atualizados!` : ''
    async fillLotesFieldOptions({ newQuadraSelect }) {
      if (!register.quadra) { return }
      // const quadraInfo = this.extractQuadraInfo(page.Form.$refs.FormLines, register.quadra)
      const quadraInfo = newQuadraSelect.extractInfo(register.quadra)
      // const fieldLotes = js_functions.getJSONObject({ fields: FormLines.fields, name: 'lote', multilines: 'lotes' })
      const fieldLotes = js_functions.getJSONObject({ fields: page.Form.$refs.FormLines.fields, name: 'lote', multilines: 'lotes' })
      // fieldLotes.loading = FormLines.styles["--over-color"]
      let options = []
      const lotes = await this.getLotes({ query: { tipoQuadra: quadraInfo.tipo, setor: quadraInfo.setor, quadra: quadraInfo.quadra }, project: { lote: 1, condominio: 1 } })
      options = lotes.map(e => ({ option: { text: `${e.lote}${(e.condominio && e.condominio != '00') ? ' (' +  e.condominio + ')' : ''}`, value: e._id } })).sort((a, b) => a.option.text.localeCompare(b.option.text))
      options.unshift({ "option": { "text": "", "value": "" } })
      fieldLotes.options = options
      fieldLotes.loading = 'false'
      // const fieldRestricoes = js_functions.getJSONObject({ fields: FormLines.fields, name: 'lote', multilines: 'restricoes' })
      // fieldRestricoes.loading = FormLines.styles["--over-color"]
      // fieldRestricoes.options = options
      // fieldRestricoes.loading = 'false'
    }
  }
  // class QuadraSelect {
  //   #field
  //   constructor({ field }) {
  //     this.#field = field
  //   }
  //   extractInfo(quadraId) {
  //     // let fieldQuadra = FormLines.Form.js_functions.getJSONObject({ fields: FormLines.fields, name: 'quadra' })
  //     // let fieldQuadra = js_functions.getJSONObject({ fields: FormLines.fields, name: 'quadra' })
  //     const quadraText = this.#field.options.find(option => option.option.value == quadraId).option.text
  //     const tipo = quadraText.split(' | ')[0]
  //     const [setor, quadra] = quadraText.split(' | ')[1].split(' (')[0].split('.')
  //     return { setor, quadra, tipo }
  //   }
  // }
  // class Poligono {}
  // class UpdateLotePoligono extends Poligono {
  //   #options
  //   #data
  //   constructor({ data, coords, options,  }) {
  //     this.#options = options
  //     this.#data = data
  //   }
  //   get options() {
  //     return newLotesMultilines.updateInfo.some(info => info.data?._id == lote._id)
  //       ? this.#options('selectedAux')
  //       : this.#options('notSelected')
  //   }
  //   get info() {
  //     return `<h3 class="infowindowTitle">Lote</h3><h3>${lote.lote || '-'}</h3>
  //       <div class="infoBlock">
  //           <ul>
  //             <li><label>Condomínio:</label> ${lote.condominio || '-'}</li>
  //           </ul>
  //       </div>
  //       <div class="infoButtons">
  //         ${!polygonsAux.some(polygon => polygon._id == this.#data._id) ? '<button type="button" id="infoWindow_cadastrarLote">Cadastrar</button>' : ''}
  //         ${polygonsAux.some(polygon => polygon._id == this.#data._id) ? '<button type="button" id="infoWindow_descadastrarLote">Descadastrar</button>' : ''}
  //       </div>`
  //   }
  // }
  this.updateLotesLinks = async function() {
    this.newLotesMultilines = await (new lotesMultilines({ dataId: lotesCurrentDataId, _register: register, lastDataId: lotesLastDataId })).build({ linkDataVersionsManager: _LDVM })
    this.newLotesMap = await (new lotesMap({ lotes: register.poligono, newLotesMultilines: this.newLotesMultilines }))
    const newFieldsToUpdate = new fieldsToUpdate({ fields: [this.newLotesMultilines, this.newLotesMap] })
    const updateDataIdDialog = new dialog({ page, data: {
      title: 'É possível atualizar os lotes!',
      text: `Os lotes vinculados a este registro estão na versão '${lotesCurrentLabel}' e podem ser atualizados para aversão '${lotesLastLabel}'. Deseja fazer a atualização?`,
      buttonYes: 'Sim, pode atualizar!',
      buttonNo: 'Não atualizar',
      action: async () => {
        await newFieldsToUpdate.updateAllDataId()
        _LDVM.updateLinkVersion({ register })
        page.showSuccess({ text: newFieldsToUpdate.sucessUpdatedAllText(), dismissible: true }),
        page.closeDialog()
      },
    } })
    const cannotUpdateDataIdDialog = new dialog({ page, data: {
      title: 'Problemas na atualização dos lotes!',
      text: newFieldsToUpdate.cannotUpdateAllText(),
      buttonYes: 'Selecionar',
      buttonNo: 'Cancelar',
      action: async () => {
        page.$store.commit("showOverlay")
        page.closeDialog()
        const { tipoQuadra, setor, quadra } = this.getQuadraInfo()
        const lotes = (await this.getLotes({ dataId: lotesLastDataId, query: { tipoQuadra, setor, quadra }, project: { lote: 1, condominio: 1, poligono: 1, sqlc: 1 } }))
          .map(lote => { return {
            type: 'Polygon',
            coords: lote.poligono?.[0] || [],
            data: lote,
            options: () =>
              this.newLotesMultilines.updateInfo.some(info => info.data?._id == lote._id)
              ? lotesPolygonsOptions('selectedAux')
              : lotesPolygonsOptions('notSelected'),
            info: () => {
              return `<h3 class="infowindowTitle">Lote</h3><h3>${lote.lote || '-'}</h3>
              <div class="infoBlock">
                  <ul>
                    <li><label>Condomínio:</label> ${lote.condominio || '-'}</li>
                  </ul>
              </div>
              <div class="infoButtons">
                ${!polygonsAux.some(polygon => polygon._id == lote._id) ? '<button type="button" id="infoWindow_cadastrarLote">Cadastrar</button>' : ''}
                ${polygonsAux.some(polygon => polygon._id == lote._id) ? '<button type="button" id="infoWindow_descadastrarLote">Descadastrar</button>' : ''}
              </div>`
            },
            events: [
              {
                obj: '#infoWindow_cadastrarLote',
                event: 'onclick',
                action: async ({ object, infoWindow, data }) => {
                  page.$store.commit("showOverlay")
                  polygonsAux.push(data)
                  object.setOptions(lotesPolygonsOptions('selectedAux'))
                  infoWindow.close()
                  page.$store.commit("hideOverlay")
                }
              },
              {
                obj: '#infoWindow_descadastrarLote',
                event: 'onclick',
                action: async ({ object, infoWindow, data }) => {
                  page.$store.commit("showOverlay")
                  polygonsAux.splice(polygonsAux.findIndex(polygon => polygon._id == data._id), 1)
                  object.setOptions(lotesPolygonsOptions('notSelected'))
                  infoWindow.close()
                  page.$store.commit("hideOverlay")
                }
              },
              {
                obj: '#infoWindow_removerLote',
                event: 'onclick',
                action: ({ object, infoWindow }) => {
                  object.setMap(null)
                  infoWindow.close()
                }
              }
            ]
            // infoRight,
            // eventsRight
          } })
        this.newLotesMap.openMapAux({ mainTitle: `Como está hoje (${lotesCurrentLabel})`, auxTitle: `Como vai ficar (${lotesLastLabel})` })
        polygonsAux.clear()
        lotes.filter(lote => this.newLotesMultilines.updateInfo.some(info => info.data?._id == lote.data._id)).map(lote => polygonsAux.push(lote.data))
        const newLotesMapAux = await (new lotesMap({ div: document.getElementById('divMapAux'), lotes, newLotesMultilines: this.newLotesMultilines }))
        newLotesMapAux.sync(page.Form.maps.poligono)
        newLotesMapAux.insertAll()
        page.$store.commit("hideOverlay")
      },
    } })
    newFieldsToUpdate.checkAll('isDataIdUpdated')
      ? page.showSuccess({ text: `Os lotes já estão atualizados!`, dismissible: true })
      : await newFieldsToUpdate.checkAll('canUpdateDataId')
        ? updateDataIdDialog.open()
        : cannotUpdateDataIdDialog.open()
  }
  this.updateLotesAux = async function() {
    page.$store.commit("showOverlay")
    _LDVM.updateLinkVersion({ register })
    lotesCurrentDataId = _LDVM.getCurrentDataId({ register })
    await this.fillLotesFieldOptions(page.Form.$refs.FormLines, page.Form.register.quadra)
    // const newQuadraSelect = new QuadraSelect({ field: js_functions.getJSONObject({ fields: page.Form.$refs.FormLines.fields, name: 'quadra' }) })
    // this.newLotesMultilines.fillLotesFieldOptions({ newQuadraSelect })
    page.Form.register.lotes = polygonsAux.map(data => { return {
      lote: data._id, link_lote: data.lote, digito: '', areaIPTU: '', contribuinte: '', codlog: '', testada: '', valorOutorga: ''
    } })
    page.Form.register.lotes.forEach(async (line, index) => await this.fillLoteInfo({ _id: line.lote, vmodel: page.Form.register.lotes[index] }))
    page.Form.register.poligono = polygonsAux.map(data => { return {
      info: { Lote: data.lote }, poligono: data.poligono[0]
    } })
    this.reCalculeFieldsByChangeLotes({ register: page.Form.register, fields: page.Form.$refs.FormLines.fields })
    this.newLotesMap.closeMapAux()
    page.showSuccess({ text: `Os lotes foram atualizados com sucesso! Ainda é necessário gravar o registro.`, dismissible: true })
    page.$store.commit("hideOverlay")
  }
  this.fillLoteInfo = async function({ _id, vmodel }) {
    const lote = await this.getLotes({ action: 'read/one', query: { _id }, project: { setor: 1, quadra: 1, lote: 1, condominio: 1 } })
    const iptu = await (new iptus()).getIPTU({ action: 'read/one', project: { "NUMERO DO CONTRIBUINTE": 1, "AREA DO TERRENO": 1, "CODLOG DO IMOVEL": 1, "TESTADA PARA CALCULO": 1 }, info: lote })
    if (iptu) {
      vmodel.digito = (lote.lote && lote.lote != '0000') ? iptu["NUMERO DO CONTRIBUINTE"].split('-')[1] : ''
      vmodel.areaIPTU = `${iptu["AREA DO TERRENO"] || '0'},00`
      vmodel.contribuinte = (lote.lote && lote.lote != '0000') ? iptu["NUMERO DO CONTRIBUINTE"].replace(/^(\d{3})(\d{3})(\d{4})*/, '$1.$2.$3') : ''
      // tegra não usa a linha acima porque não tem a coluna contribuinte no multilines
      vmodel.codlog = iptu["CODLOG DO IMOVEL"]
      vmodel.testada = iptu["TESTADA PARA CALCULO"] ? iptu["TESTADA PARA CALCULO"] : ''
      const _codlog = iptu["CODLOG DO IMOVEL"].replace('-', '')
      const sq = `${lote.setor}${lote.quadra}`
      const vtoCurrentDataId = (new vtos()).lastDataId
      const _vtos = (await js_functions.getFunc({ func: () => universal.getGenericRegisters({ company: 'public', dataId: vtoCurrentDataId, query: { sq: sq, codlog: _codlog }, project: { valor: 1 } }), returnData: true })).data
      if (Array.isArray(_vtos) && _vtos[0]) { vmodel.valorOutorga = _vtos[0].valor }
    }
  }
  this.fillAllLotesInfo = async function({ FormLines }) {
    const project = {
      "NUMERO DO CONTRIBUINTE": 1,
      "AREA DO TERRENO": 1,
      "CODLOG DO IMOVEL": 1,
      "NUMERO DO CONDOMINIO": 1,
      "TESTADA PARA CALCULO": 1,
      "NOME DE LOGRADOURO DO IMOVEL": 1
    }
    const quadra = FormLines.register.quadra
    const quadraInfo = this.extractQuadraInfo(FormLines, quadra)
    const iptu = await (new iptus()).getIPTU({ project, info: { setor: quadraInfo.setor, quadra: quadraInfo.quadra } })
    const vtoCurrentDataId = (new vtos()).lastDataId
    const vto = (await FormLines.Form.js_functions.getFunc({ FormLines, func: () => universal.getGenericRegisters({ company: 'public', module: 'data', dataId: vtoCurrentDataId, query: { sq: { $in: [`${quadraInfo.setor}${quadraInfo.quadra}`] } }, project: { sq: 1, codlog: 1, valor: 1 } }), returnData: true })).data
    FormLines.register.lotes.map(loteLine => {
      const link_lote = loteLine.link_lote
      const condominio = link_lote?.substringBetween('(', ')')
      this.fillLoteInfo({ _id: loteLine.lote, vmodel: loteLine })
      const { digito, areaIPTU, contribuinte, codlog, testada, vtoValor } = this.extractIPTUInfo({ iptu, vto, setor: quadraInfo.setor, quadra: quadraInfo.quadra, lote: loteLine.link_lote, condominio })
      loteLine.digito = digito
      loteLine.areaIPTU = areaIPTU
      loteLine.contribuinte = contribuinte
      loteLine.codlog = codlog
      loteLine.testada = testada
      loteLine.valorOutorga = vtoValor
    })
  }
  this.extractQuadraInfo = function(FormLines, _quadra) {
    // let fieldQuadra = FormLines.Form.js_functions.getJSONObject({ fields: FormLines.fields, name: 'quadra' })
    let fieldQuadra = js_functions.getJSONObject({ fields: FormLines.fields, name: 'quadra' })
    const quadraText = fieldQuadra.options.find(option => option.option.value == _quadra).option.text
    const tipo = quadraText.split(' | ')[0]
    const [setor, quadra] = quadraText.split(' | ')[1].split(' (')[0].split('.')
    return { setor, quadra, tipo }
  }
  this.fillLotesFieldOptions = async function(FormLines, quadra) {
    // não tem restricões no formulário de concorrentes, erro no console: Cannot read properties of undefined (reading 'fields')
    if (!quadra) { return }
    const quadraInfo = this.extractQuadraInfo(FormLines, quadra)
    const fieldLotes =js_functions.getJSONObject({ fields: FormLines.fields, name: 'lote', multilines: 'lotes' })
    const fieldRestricoes = js_functions.getJSONObject({ fields: FormLines.fields, name: 'lote', multilines: 'restricoes' })
    fieldLotes.loading = FormLines.styles["--over-color"]
    fieldRestricoes.loading = FormLines.styles["--over-color"]
    let options = []
    const lotes = await this.getLotes({ query: { tipoQuadra: quadraInfo.tipo, setor: quadraInfo.setor, quadra: quadraInfo.quadra }, project: { lote: 1, condominio: 1 } })
    options = lotes.map(e => ({ option: { text: `${e.lote}${(e.condominio && e.condominio != '00') ? ' (' +  e.condominio + ')' : ''}`, value: e._id } })).sort((a, b) => a.option.text.localeCompare(b.option.text))
    options.unshift({ "option": { "text": "", "value": "" } })
    fieldLotes.options = options
    fieldRestricoes.options = options
    fieldLotes.loading = 'false'
    fieldRestricoes.loading = 'false'
  }
  this.extractIPTUInfo = function({ iptu, vto, setor, quadra, lote, condominio }) { // remover função do mapfunctions_public
    const _iptu = (lote != '0000')
      ? iptu?.find(register => register["NUMERO DO CONTRIBUINTE"].startsWith(`${setor}${quadra}${lote}-`))
      : iptu?.find(register => register["NUMERO DO CONTRIBUINTE"].startsWith(`${setor}${quadra}`) && register["NUMERO DO CONDOMINIO"].startsWith(`${condominio}-`))
    const digito = (lote != '0000' && _iptu && _iptu["NUMERO DO CONTRIBUINTE"]) ? _iptu["NUMERO DO CONTRIBUINTE"].split('-')[1] : ''
    const areaIPTU = (_iptu && _iptu["AREA DO TERRENO"]) ? `${_iptu["AREA DO TERRENO"]},00` : ''
    const contribuinte = (lote != '0000' && _iptu && _iptu["NUMERO DO CONTRIBUINTE"]) ? _iptu["NUMERO DO CONTRIBUINTE"].replace(/^(\d{3})(\d{3})(\d{4})*/, '$1.$2.$3') : ''
    const codlog = (_iptu && _iptu["CODLOG DO IMOVEL"]) ? _iptu["CODLOG DO IMOVEL"] : ''
    const testada = (_iptu && _iptu["TESTADA PARA CALCULO"]) ? _iptu["TESTADA PARA CALCULO"] : ''
    const logradouro = (_iptu && _iptu["NOME DE LOGRADOURO DO IMOVEL"]) ? _iptu["NOME DE LOGRADOURO DO IMOVEL"] : ''
    const _vto = vto?.find(register => register.codlog == codlog.replace('-', ''))
    const vtoValor = (_vto && _vto.valor) ? _vto.valor : ''
    return { digito, areaIPTU, contribuinte, codlog, testada, logradouro, vtoValor }
  }
  this.conditionalShowOnMap = function(array) {
    if (Array.isArray(this.polygonsOnMap[array]) && this.polygonsOnMap[array].length > 0) {
      this.polygonsOnMap[array].map(polygon => polygon.setMap(null))
      this.polygonsOnMap[array] = []
      return false
    }
    return true
  }
  this.showTerrenosConflitadosInMap = async function(FormLines, dataId) {
    if (!this.conditionalShowOnMap('conflitos')) { return }
    if (!FormLines.register.link_quadra) { return window.$showAlert({ type: 'warning', text: 'Selecione uma quadra!', dismissible: true }) }
    const _terrenosAtivosNaQuadra = await this.getTerrenosConflitados(FormLines)
    const _terrenosAtivosNaQuadraReplaced = await universal.replaceAllLinks({ company: window.company, originalData: _terrenosAtivosNaQuadra, dataId: dataId || 'terrenos' })
    const terrenosAtivosNaQuadra = _terrenosAtivosNaQuadraReplaced?.filter(terreno => terreno.link_status != 'Descartado')
    if (!terrenosAtivosNaQuadra || terrenosAtivosNaQuadra.length == 0) { return window.$showAlert({ type: 'warning', text: 'Nenhum conflito encontrado!', dismissible: true }) }
    this.showTerrenosInMap(FormLines, terrenosAtivosNaQuadra)
  }
  this.getTerrenosConflitados = async function(FormLines) {
    return (await FormLines.Form.js_functions.getFunc({ FormLines, func: () => universal.getGenericRegisters({ dataId: 'terrenos_all', query: { link_quadra: FormLines.register.link_quadra, link_status: { $nin: ['Descartado', 'Landbank'] }, _id: { $ne: FormLines.register._id } }, project: { _id: 1, responsaveis: 1, poligono: 1, nome: 1, status: 1, link_status: 1, historico: 1 } }), returnData: true })).data
  }
  this.showTerrenosInMap = function(FormLines, terrenos) {
    terrenos?.map(terreno => {
      if (terreno._id !== FormLines.register._id) { terreno.poligono.map(poligono => this.polygonsOnMap.conflitos.push(FormLines.Form.map_functions.showPolygonInMap({ map: FormLines.Form.maps.poligono, polygon: poligono.poligono ? poligono.poligono : poligono, name: terreno.nome }))) }
    })
  }
  this.reCalculeFieldsByChangeLotes = function({ register, fields }) {
    new iptus().calculeSumAreaIPTU({ register, fields })
    new vtos().calculeMaxVTO({ fields, lotes: register.lotes })
    // this.calculeTestadas(register)
  }
  this.calculeTestadas = function(register) {
    const _testadas = register.testadas
    register.testadas = []
    const _lotes = register.lotes.filter(lote => lote.codlog != undefined)
    const getLogradouro = async (codlog) => {
      const _testada = _testadas.find(testada => testada.codlog == codlog)
      if (_testada && _testada.logradouro) { return _testada.logradouro }
      const iptuCurrentDataId = (new iptus()).getCurrentDataId({ register })
      return (await js_functions.getFunc({ func: () => universal.getGenericRegisters({ company: 'public', dataId: iptuCurrentDataId, action: 'read/one', query: { ["CODLOG DO IMOVEL"]: codlog }, project: { "NOME DE LOGRADOURO DO IMOVEL": 1 } }), returnData: true, showAlert: false }))?.data?.["NOME DE LOGRADOURO DO IMOVEL"]
    }
    const sumTestadas = (codlog) => js_functions.numberOut(_lotes.reduce((sum, lote) => sum += lote.codlog == codlog ? js_functions.numberIn(lote.testada) : 0, 0), 2, "0,00")
    js_functions.executeIfUniq({ array: _lotes, prop: "codlog", func: async (lote) => { register.testadas.push({ logradouro: await getLogradouro(lote.codlog), codlog: lote.codlog, testada: sumTestadas(lote.codlog) }) } })
  }
  this.getQuadraInfo = function() {
    const quadra = page.Form.register.link_quadra
    if (!quadra) {
      page.$store.commit("hideOverlay")
      return page.$store.commit("showAlert", { type: 'warning', text: 'Selecione uma quadra!', dismissible: true })
    }
    if (!page.Form.register.poligono[0]?.info?.Lote) { page.Form.register.poligono = [] }
    const _tipo = quadra.split(' | ')[0]
    const [_setor, _quadra] = quadra.split(' | ')[1].split(' (')[0].split('.')
    return { tipoQuadra: _tipo, setor: _setor, quadra: _quadra }
  }
  this.showLotesPoligonosOnMap = async function(FormLines) {
    FormLines.$store.commit("showOverlay")
    if (!this.conditionalShowOnMap('lotes')) { return FormLines.$store.commit("hideOverlay") }
    // ---
    // const { tipoQuadra, setor, quadra } = this.getQuadraInfo()
    // const lotes = await this.getLotes({ query: { tipoQuadra, setor, quadra }, project: { lote: 1, condominio: 1, poligono: 1, sqlc: 1 } })
    const quadra = FormLines.register.link_quadra
    if (!quadra) {
      FormLines.$store.commit("hideOverlay")
      return FormLines.$store.commit("showAlert", { type: 'warning', text: 'Selecione uma quadra!', dismissible: true })
    }
    if (!FormLines.register.poligono[0]?.info?.Lote) { FormLines.register.poligono = [] }
    const _tipo = quadra.split(' | ')[0]
    const [_setor, _quadra] = quadra.split(' | ')[1].split(' (')[0].split('.')
    const lotes = await this.getLotes({ query: { tipoQuadra: _tipo, setor: _setor, quadra: _quadra }, project: { lote: 1, condominio: 1, poligono: 1, sqlc: 1 } })
    // ---
    this.showLotesInMap(FormLines, lotes)
    FormLines.$store.commit("hideOverlay")
  }
  this.getLotes = async function({ dataId, action, query, project }) {
    return (await window.jsf.getFunc({ func: () => universal.getGenericRegisters({ company: 'public', dataId: dataId || lotesCurrentDataId, action, query, project }), returnData: true })).data
  }
  this.getColorStatusLote = async function({ status }) {
    return (await window.jsf.getFunc({ func: () => universal.getGenericRegisters({ dataId: 'lotes_status', query: { _id: status }, project: { color: 1 } }), returnData: true })).data
  }
  this.setColorOnLote = async function({ link_status, color, poligono, link_lote }) {
    const index = this.findLineNumberPoligon({ poligono, link_lote })
    if (index == -1) return
    poligono[index].options = { fillColor: color, fillOpacity: 0.7 }
    poligono[index].info.status = link_status
    const lotePoligono = poligono[index]
    this.removerPoligonoLote({ poligono, link_lote })
    poligono.push(lotePoligono)
  }
  this.setColorStatusOnPoligonoLote = async function({ status, link_status, poligono, link_lote }) {
    const statusLote = await this.getColorStatusLote({ status })
    this.setColorOnLote({ link_status, color: statusLote[0].color, poligono, link_lote })
  }
  this.findLineNumberPoligon = function({ poligono, link_lote }) {
    const findId = element => element.info.Lote == link_lote ? true : false
    return poligono.findIndex(findId)
  }
  this.removerPoligonoLote = function({ poligono, link_lote }) {
    const index = this.findLineNumberPoligon({ poligono, link_lote })
    poligono.splice(index, 1)
  }
  this.adicionarLoteNoMapa = async function({ lote, link_lote, poligono, status }) {
    if (!lote) { return window.$showAlert({ type: 'warning', text: 'Selecione um lote!', dismissible: true }) }
    const index = this.findLineNumberPoligon({ poligono, link_lote })
    if (index != -1) { return window.$showAlert({ type: 'warning', text: 'Lote já estava no mapa!', dismissible: true }) }
    const lotePoligono = await this.getLotes({ query: { _id: lote }, project: { poligono: 1 } })
    const lotePoligonoAndInfo = { info: { Lote: link_lote }, poligono: lotePoligono[0].poligono[0] }
    if (status) {
      const statusLote = await this.getColorStatusLote({ status })
      lotePoligonoAndInfo.options = { fillColor: statusLote[0].color, fillOpacity: 0.77 }
    }
    poligono.push(lotePoligonoAndInfo)
  }
  this.showLotesInMap = function(FormLines, lotes) {
    lotes?.map(lote => lote.poligono.map(poligono => this.polygonsOnMap.lotes.push(FormLines.Form.map_functions.showPolygonInMap({
      map: FormLines.Form.maps.poligono,
      polygon: poligono,
      FormLines,
      options: { fillColor: "#CCCCCC", fillOpacity: 0.2, strokeColor: "#666666", strokeOpacity: 0.8, strokeWeight: 0.4, zIndex: 1 },
      lote,
      info: (builder) => {
        return `<h3 class="infowindowTitle">Lote</h3><h3>${builder.lote.lote || '-'}</h3>
        <div class="infoBlock">
            <ul>
              <li><label>Condomínio:</label> ${builder.lote.condominio || '-'}</li>
            </ul>
        </div>
        <div class="infoButtons">
          <button type="button" id="infoWindow_cadastrarLote">Cadastrar</button>
          <button type="button" id="infoWindow_removerLote">Remover</button>
          <button type="button" id="infoWindow_usarEndereco">Usar Endereço</button>
        </div>`
      },
      events: [
        {
          obj: '#infoWindow_cadastrarLote',
          event: 'onclick',
          action: async (params) => {
            params.builder.FormLines.$store.commit("showOverlay")
            params.builder.FormLines.register.lotes.push({ lote: params.builder.lote._id, link_lote: params.builder.lote.lote, digito: '', areaIPTU: '', contribuinte: '', codlog: '', testada: '', valorOutorga: '' })
            await this.fillLoteInfo({ _id: params.builder.lote._id, vmodel: params.builder.FormLines.register.lotes.getLast() })
            params.builder.FormLines.register.poligono.push({ poligono: params.builder.lote.poligono[0], info: { Lote: params.builder.lote.lote } })
            this.reCalculeFieldsByChangeLotes({ register: params.builder.FormLines.register, fields: params.builder.FormLines.fields })
            params.builder.FormLines.$store.commit("hideOverlay")
            params.infoWindow.close()
          }
        },
        {
          obj: '#infoWindow_removerLote',
          event: 'onclick',
          action: (params) => {
            params.object.setMap(null)
            params.infoWindow.close()
          }
        },
        {
          obj: '#infoWindow_usarEndereco',
          event: 'onclick',
          action: async (params) => {
            const iptuCurrentDataId = (new iptus()).getCurrentDataId({ register })
            const iptu = (await window.jsf.getFunc({ func: () => universal.getGenericRegisters({ company: 'public', dataId: iptuCurrentDataId, query: { sqlc: params.builder.lote.sqlc }, project: { "NOME DE LOGRADOURO DO IMOVEL": 1, "NUMERO DO IMOVEL": 1 }, action: 'read/one' }), returnData: true })).data
            params.builder.FormLines.register.endereco = iptu ? `${iptu?.["NOME DE LOGRADOURO DO IMOVEL"]} ${iptu?.["NUMERO DO IMOVEL"]}` : params.builder.FormLines.register.endereco
            params.infoWindow.close()
          }
        }
      ]
    }))))
  }
}