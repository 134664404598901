"use strict"

export class multilines {
  #dataId
  #lines
  constructor({ dataId, data }) {
    class lines extends Array {
      constructor({ data }) {
        class line {
          constructor({ data }) {
            Object.assign(this, data)
          }
        }
        super(...(data?.map(lineData => new line({ data: lineData })) || ''))
      }
    }
    this.#dataId = dataId
    this.#lines = new lines({ data })
  }
  get dataId() { return this.#dataId }
  get lines() { return this.#lines }
  executeInAllLines = async ({ func }) => await Promise.all(this.#lines.map((line, index) => func({ line, index })))
}